import axios from 'axios'
import router from "@/router/index";
import Vue from 'vue'; // 引入Vue实例
const vueInstance = new Vue();
export const request = axios.create({
    baseURL: 'https://java.juanbudong.cn',
    // baseURL:  'https://66591d78.r28.cpolar.top',
    // baseURL : 'http://localhost:8888',
    timeout: 30000
})

request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json'
    // let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem("user")) : null
    config.headers['Authorization'] = localStorage.getItem('token') ? localStorage.getItem('token').slice(1, -1) :''
    return config
}, error => {
    console.error('request error: ' + error)
    return Promise.reject(error)
})


request.interceptors.response.use(response => {
    let res = response.data;
    if (typeof res === 'string') {
        res = res ? JSON.parse(res) : res
    }
    return res
},error => {
    // 处理请求错误
    const self = this
    if (error.response && error.response.status === 700) {
        // 当响应状态为 700 时，跳转到登录页
        // this.$message.error('权限过期')
        localStorage.setItem('token','')
       return router.push('/login').then(r => {  vueInstance.$message.error('会话过期')});
    } else {
        // 打印失败原因
       return  vueInstance.$message.error(error)
    }
    return Promise.reject(error);
})


// request.interceptors.response.use(response => {
//     let res = response.data;
//     if (typeof res === 'string') {
//         res = res ? JSON.parse(res) : null;
//     }
//     console.log('状态码' + response.status);
//     return res;
// }, error => {
//     if (error.response && error.response.status === 700) {
//         // router.push('/home').then(r => console.log(11));
//         router.push('/home');
//         console.error('response error: ' + error);
//         // console.log('状态码11111111' + response.status);
//     } else {
//         console.error('response error: ' + error);
//     }
//     return Promise.reject(error);
// });

export default request

