import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
    if (onResolve || onReject){
        return originalPush.call(this, location, onResolve, onReject)
    }
    return originalPush.call(this, location).catch(err => err)
}


export const routes = [
    {
        path: '/',
        name: 'Menu',
        redirect:'/home',
        component: () => import('../views/Menu.vue'),
        children:[
            {path:'/home',name:'Home',component:()=>import('../views/Home.vue')},
            // {path:'/map',name:'Map',component:()=>import('../views/Map.vue')},
            // {path: '/deliveryLine',name: 'DeliveryLine',component:()=>import('../views/route/DeliveryLine.vue')},
            // {path: '/deliveryList',name: 'DeliveryList',component:()=>import('../views/route/DeliveryList.vue')},
            // {path: '/receiverLine',name: 'ReceiverLine',component:()=>import('../views/route/ReceiverLine.vue')},
            // {path: '/receiverList',name: 'ReceiverList',component:()=>import('../views/route/ReceiverList.vue')},
            // {path: '/chat',name: 'Chat',component:()=>import('../views/chat/chat.vue')},
            {path: '/personInfo',name: 'PersonInfo',component:()=>import('../views/PersonInfo')},
        ]
    },
    {
        path: '/login',
        name: 'Login',
        redirect:'/login',
        component: () =>
            import('../views/Login.vue'),
        children:[
            {path:'/login',name:'Login',component:()=>import('../views/Login.vue')}
        ]
    },
    // {
    //     path: '/register',
    //     name: 'Register',
    //     component: () =>
    //         import('../views/Register.vue')
    //
    // },
    // {
    //     path: '/echarts',
    //     name: 'Pin',
    //     component: () =>
    //         import('../views/echarts/Pin.vue')
    //
    // },
    // {
    //     path: '/info',
    //     name: 'MyInfo',
    //     component: () =>
    //         import('../views/MyInfo.vue')
    //
    // },
    // {
    //     path: '/map',
    //     name: 'Map',
    //     component:() => import('../views/Map.vue')
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
