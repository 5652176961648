import Vue from 'vue'
import App from './App.vue'
import router from "@/router/index"
import '@/assets/css/global.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI,{size:'small'})

import request from "@/utils/request";
Vue.prototype.$request = request

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
Vue.use(ElementUI);
Vue.config.errorHandler = function (err, vm, info) {
  // 处理错误，例如发送到错误日志服务器
  console.error('Error:', err, 'Vue instance:', vm, 'Info:', info);
};